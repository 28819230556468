#codeFollowBtnContainer{
    width: 85vw;
    display: flex;
    flex-direction: column;
    margin-left: 7.5vw;
    margin-bottom: 7.5vh;
    margin-top : 5vh;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
}

#CodeFollowBtn{
    width: 83vw;
    height : 5vh;
    margin-top: 1vw;
    margin-left: 1vw;
    margin-bottom: 1vw;
    margin-right: 1vw;
    font-family: 'SUITE-Regular';
    font-weight: bold;

    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#codeFollowGroupSetting{
    width: 85vw;
    height : 5vh;
    margin-top: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap : 20px
}

#CodeFollowBtn:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}

#CodeGroupDetail{
    display: flex;
    flex-direction: column;
}
