#NewCodeGroupBtn{
    width: 85vw;
    height : 5vh;
    margin-left: 7.5vw;
    background-color: white;
    font-family: 'SUITE-Regular';
    font-weight: bold;
    color: #EF4949;

    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#NewCodeGroupBtn:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}
