#CodeGroupFollowBtn {
    width: 85vw;
    height: 5vh;
    margin-left: 7.5vw;
    background-color: white;
    font-family: 'SUITE-Regular';
    font-weight: bold;
    color: #009418;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

#CodeGroupFollowBtn:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
