#CodeDetailBody{
    width: 85vw;
    margin-top: 30px;
    margin-left: 7.5vw;
    margin-right : 7.5vw;
    display: flex;
    flex-direction: column;
}

#CodeDetailTitle{
    display: flex;
    justify-content: start;
    gap: 20px;
    padding-top: 25px;
    padding-bottom: 5px;
    font-weight: 400;
    font-size: medium;
}

#CodeContent{
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */

    width: 95%;
    padding-top:2.5%;
    padding-left : 2.5%;
    padding-right : 2.5%;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom :15px;
    font-family: 'intelone-mono-font-family-regular';
    font-size: medium;
    resize: none;
    outline: none;
}

#CodeDetailUserProfile{
    height: 8vh;
    margin-top:1vh;
    margin-bottom: 1vh;
    margin-left: 1vh;
    margin-right: 1vh;
}

#CodeDescriptionContent{
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    width: 95%;
    padding-top:30px;
    padding-left : 2.5%;
    padding-right : 2.5%;
    padding-bottom: 30px;
    font-family: 'SUITE-Regular';
    margin-top: 15px;
    margin-bottom :15px;
    font-size: large;
    resize: none;
    outline: none;
}


#UserContent{
    height: 10vh;
    width: 70%;
    display: flex;
    justify-content: start;
    gap: 30px;
    font-size: large;
    display: flex;
    align-items: center;  
}

#UserContentContainer{
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#CodeModNDelBtn{
    width: 20%;
    height : 6vh;
    margin-right: 2.5vw;
    font-family: 'SUITE-Regular';
    font-weight: bold;

    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#CodeModNDelBtn:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}