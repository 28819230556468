.new-code-banner {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'SUITE-Regular';
  font-size: medium;
  z-index: 1000;
}

.new-code-banner.success {
  border: 2px solid #5C4EFF;
}

.new-code-banner.error {
  border: 2px solid #ff8f87;
  color: #ff8f87;
}

.new-code-banner-message.error{
  margin-right: 20px;
  color: #ff8f87;
}

.new-code-banner-message.success{
  margin-right: 20px;
  color: #5C4EFF;
}

.confirm-button {
  padding: 10px 20px;
  color: #5C4EFF;
  background-color: #FFF;
  border: 2px solid #5C4EFF;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-button:hover {
  background-color: #5C4EFF;
  border: 2px solid #5C4EFF;
  color:#FFF;
}

#UploadSettingInfo {
    display: flex;
    gap: 30px;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    width: 95%;
    padding-top:15px;
    padding-left : 2.5%;
    padding-right : 2.5%;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom :15px;
    font-size: medium;
}

#CodeUploadTitle {
    display: flex;
    justify-content: start;
    gap: 20px;
    padding-top: 25px;
    padding-bottom: 5px;
    font-weight: 500;
    font-size: medium;
}

#UploadCode {
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    width: 95%;
    padding-top:15px;
    padding-left : 2.5%;
    padding-right : 2.5%;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom :15px;
    font-family: 'intelone-mono-font-family-regular';
    font-size: medium;
    resize: none;
    outline: none;
}

#UploadDescription {
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    width: 95%;
    padding-top:15px;
    padding-left : 2.5%;
    padding-right : 2.5%;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom :15px;
    font-family: 'SUITE-Regular';
    font-size: large;
    resize: none;
    outline: none;
}

#CodeUploadwBtn {
    width: 85vw;
    height : 6.125vh;
    margin-top: 5vh;
    margin-bottom: 10vh;
    margin-left: 7.5vw;
    background-color: white;
    font-size: medium;
    font-weight: bold;
    font-family: 'SUITE-Regular';
    color : #EF4949;

    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#CodeUploadwBtn:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}

#UploadSettingInfo {
    display: flex;
    gap: 10px;
}

#UploadSettingInfo button {
    background: none;
    font-family: 'SUITE-Regular';
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
    transition: color 0.3s;
}

#UploadSettingInfo button.selected {
    font-weight: bold;
    color: #EF4949;
}

#UploadSettingInfo button:hover {
    color: #F5A3A3; /* Lighter red for hover */
}
