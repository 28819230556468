.code-group-container {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    width: 80%;
    margin: 0 auto;
}

.input-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
}

.input-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.input-group input,
.input-group select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px; /* Adjusted height */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px; /* Adjusted to match the new height */
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px; /* Adjusted height */
    width: 14px; /* Adjusted width */
    left: 3px; /* Adjusted positioning */
    bottom: 3px; /* Adjusted positioning */
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #EF4949;
}

input:checked + .slider:before {
    transform: translateX(40px); /* Adjusted to match the new width */
}

#NewCodeGroupBtn {
    padding: 10px 20px;
    color : #EF4949;
    font-size: medium;
    cursor: pointer;

    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#NewCodeGroupBtn:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}
