.contribution-graph {
    display: flex;
}

.week {
    display: flex;
    flex-direction: column;
    margin-right: 10px; /* Adjust margin for spacing between weeks */
}

.day {
    width: 10px; /* Adjusted width */
    height: 10px; /* Adjusted height */
    margin: 3px 0; /* Adjust margin for spacing between days */
    background-color: #ebedf0;
    position: relative;
}

.day:hover::after {
    content: attr(title);
    position: absolute;
    bottom: 25px; /* Adjusted for the new size */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    z-index: 10;
}

.day-0 { background-color: #d6d6d6 ;}
.day-1 { background-color: #feb8b8; } /* 0-1 학습 횟수 */
.day-2 { background-color: #ff9d9d; } /* 2 학습 횟수 */
.day-3 { background-color: #ff7b7b; } /* 3 학습 횟수 */
.day-4 { background-color: #e72f2f; } /* 4 이상 학습 횟수 */
.today { background-color: #5C4EFF; } /* 오늘 */