#CodeDetailInfo{
    width: 80%;  
    padding-left : 5%;
    padding-right: 5%;
    margin-left:5%;
    margin-right: 5%;
    margin-top: 7.5vh;    
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr 1fr 0.5fr 1.5fr 1fr 0.5fr 2fr 1fr 0.5fr 2fr ;
    justify-items: center;
    font-size: medium;
    font-weight: normal;
}

#CodeDetailInfoContainer{
    width: 85%;
    margin-left : 7.5%;
    border: 0.45px solid;
    border-radius: 10px;
}