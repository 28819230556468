#StudyTracking{
    width: 85vw;
    margin-top : 7.5vh;
    margin-bottom: 10vh;    
    margin-left : 7.5vw;
    margin-right: 7.5vw;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#StudyTrackingGuide{
    display: flex;
    justify-content: center;
    align-items: center;   
    font-size: large;
    font-weight: bold;
    padding-top : 50px;
    padding-bottom : 50px;
}

#StudyTrackingTextArea{
    padding-top: 50px;
    border-top: 0.5px solid;
    border-color: gray;
}

#StudyTrackingLineSpace {
    display: flex;
    flex-direction: column;
}

#StudyTrackingBackground{
    padding-left: 30px;
    border: 0px;
    resize: none;
    outline: none;
    font-family: 'intelone-mono-font-family-regular';
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: medium;
    color: #b1b1b1;
}

#StudyTrackingInput,
textarea[id^="textarea-line-"] {
    padding-left: 30px;
    border: 0px;
    resize: none;
    outline: none;
    font-family: 'intelone-mono-font-family-regular';
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: medium;
}

#explainationBox{
    position: fixed;
    width: 80vw;
    bottom : 5vh;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    display: flex;
    gap : 20px;
    justify-content: center;
    align-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    font-size: large;
    font-weight: lighter;
}

#StudyTrackingCompleteBtn{
    height: 5vh;
    margin-top : 2vh;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    margin-bottom: 2vh;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#StudyTrackingCompleteBtn:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}

.trackingbanner {
    font-family: 'SUITE-Regular';
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    text-align: center;
    z-index: 1000;
    font-size: 18px;
    transition: transform 0.5s ease-in-out;
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
}

.trackingbanner.success {
    background-color: #4bc5fe; /* 성공: 초록색 배경 */
    color: white;
}

.trackingbanner.error {
    background-color: #ff8f87; /* 실패: 빨간색 배경 */
    color: white;
}

.trackingbanner-close-btn {
    margin-left: 40px;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.trackingbanner-close-btn.success {
  color: #4bc5fe ;
}

.trackingbanner-close-btn.error {
    color: #ff8f87 ;
  }

.trackingbanner-close-btn.success:hover {
    background-color: #4bc5fe;
    color: white;
}

.trackingbanner-close-btn.error:hover {
    background-color: #ff8f87;
    color: white;
}


