#MyProfileUserInfo{
    width: 70vw;
    height: 20vh;
    margin-top : 5vh;
    margin-bottom: 5vh;    
    margin-left : 15vw;
    margin-right: 15vw;
    display: flex;
    align-items: center;
}

#MyProfileUserImage{
    width: 10%;
    padding-left: 0.5vh;
    padding-right: 0.5vh;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    border-radius: 10px;
    margin-right: 5%;
}

#MyProfildUserInfoContainer{
    width: 80%;
    height: 10vh;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: start;
    padding-left:5%;
    padding-top:3vh;
    padding-bottom: 3vh;
    font-size: medium;
}

#TextContainer{
    display: flex;
    gap: 20px;
}

#MyProfileStudyHistory{
    width: 70vw;
    height: 35vh;
    margin-left : 15vw;
    margin-right: 15vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


#MyProfileStudyHistoryBody{
    font-size: large;
    font-weight: 500;
    width : 70vw;
    height: 40vh;
    display: flex;
}

#MyProfileStudyHistoryNumeric{
    height : 30vh;
    width: 20vw;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: start;
    margin-top:1.5vh;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    padding-left:2.5vw;
}

#MyProfileStudyHistoryGrass {
    height: 35vh;
    width: 40vw;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top:1.5vh;
}

#grass {
    width: 15px;
    height: 15px;
    background-color:rgb(200, 200, 200);
    border-radius: 3px;
}

