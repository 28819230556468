#StudyBlockOrderingCodeArea{
    border: 0px;
    padding-top:50px;
    padding-left: 20px;
    resize: none;
    font-family: 'intelone-mono-font-family-regular';
    font-size: large;
    gap : 10px;
    border-top: 0.5px solid;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: gray;
}

#StudyBlockOrdering{
    width: 85vw;
    margin-top : 7.5vh;  
    margin-left : 7.5vw ;
    margin-right: 7.5vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 30px;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
}

#exampleBox{
    position: fixed;
    width: 84.9vw;
    bottom : 13vh;
    background-color: white;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: large;
    font-weight: bold;
}

#exampleList{
    width: 70vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    font-family: 'intelone-mono-font-family-regular';
    font-size: medium;
    justify-items: center
}

#BlockOrderingBtnContainer{
    position: fixed;
    width: 84.8vw;
    bottom : 5vh;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: large;
    font-weight: bold;
}

#BlockOrderingBtn{
    width: 48%;
    height: 5vh;
    background-color: white;
    font-family: 'SUITE-Regular';
    font-weight: normal;
    font-size: medium;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#BlockOrderingBtn:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}


