#StudySettingBar{
    width: 85%;
    height: 3vh;
    margin-top: 7.5vh;    
    margin-left : 7.5vw;
    margin-right: 7.5vw;
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr 0.75fr 0.5fr 0.75fr 1fr 0.5fr 3.5fr 2fr ;
    justify-items: center;
    align-items: center;
    font-size: medium;
}

#SettingBarSetting{
    font-weight: bold;
}

#CodeGroupSetting{
    border: 0px;
    font-family: 'SUITE-Regular';
    font-size: medium;
}

#ProblemSettingIcon{
    width: 10vw;
    height: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#ProblemSettingIcon:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}

#CodeGroupSettingList{
    font-family: 'SUITE-Regular';
    background-color: white;
}