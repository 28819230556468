
#CodeHomeUploadButton{
    width: 85vw;
    height : 5vh;
    margin-left: 7.5vw;
    background-color: white;
    font-family: 'SUITE-Regular';
    font-weight: bold;
    color: #FF6B00;

    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#CodeHomeUploadButton:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}

#Codehome{
    display: flex;
    flex-direction: column;
}

#pagination{
    height : 2.5vh;
    width: 20vw;
    margin-bottom : 2.5vh;
    margin-left: 40vw;
    margin-right: 40vw;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    justify-content: center;
    align-items: center;
}

#paginationTxt{
    display: flex;
    justify-content: center;
    align-items: center;
}

#paginationBtn{
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    transition: box-shadow 0.3s ease; /* 그림자 변화에 대한 트랜지션 */
}

#paginationBtn:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스를 올렸을 때 더 큰 그림자 */
}