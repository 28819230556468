#StudyLineOrderingCodeArea{
    border: 0px;
    padding-left: 20px;
    resize: none;
    outline: none;
    font-family: 'intelone-mono-font-family-regular';
    font-size: medium;
    border-radius: 10px;
    padding-top: 10px;
}

#StudyLineOrdering{
    width: 85vw;
    height: fit-content;
    margin-top : 7.5vh;
    margin-bottom: 52vh;    
    margin-left : 7.5vw;
    margin-right: 7.5vw;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

#StudyLineOrderingInfo{
    display: flex;
    gap: 5vw;
}

#LineOrderingExampleBox{
    position: fixed;
    width: 84.9vw;
    bottom : 13vh;
    background-color: white;
    border: 1px solid #ddd; /* 연한 회색 경계선 */
    border-radius: 8px; /* 모서리를 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 */
    background-color: #fff; /* 배경색을 흰색으로 */
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: large;
    font-weight: bold;
}

#LineOredringExampleList{
    display: flex;
    width: 25vw;
    justify-content: center;
    align-items: center;
}

#LineOrderingExampleList{
    height: 20vh;
    overflow: visible;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    justify-items: center;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    padding-top : 20px;
    padding-bottom: 40px;
}

#codeSectionPagination{
    border: 0px solid;
    background-color: white;
    margin-right: 10px;
    margin-left: 10px;
    font-size: medium;
}

#StudyLineOrderingTextContainer{
    margin-left: 20px;
    margin-bottom: 30px;
    padding-top: 50px;
    border-top: 0.5px solid;
    border-color: gray;
}

